.main_container {
    overflow: hidden;
    z-index: 2;
    background: linear-gradient(#000, #080b1b, #161e49, #1400cc, #87ceeb, skyblue, rgb(13, 80, 13), #000000)
}

.intro {
    height: 100vh;
    width: 100vw;
    z-index: 500;
    position: relative;
}

.particles {
    height: 200%;
 }

.about {
    clear: both;
    color: #ffffff;
    padding-left: 6em;
    height: 100vh;
    z-index: 500;
    position: relative;
}

.skills {
    clear: both;
    content: center;
    padding-left: 6em;
    height: 120vh;
    z-index: 500;
    position: relative;
}

.projects {
    clear: both;
    padding-left: 5em;
    padding-right: 5em;
    height: 90vh;
    z-index: 500;
    position: relative;
}

.parallax {
    clear: both;
    z-index: 1;
}

.contact {
    clear: both;
    height: 5vh;
    z-index: 500;
}

#timeline {
    display: inline-block;
    position: absolute;
    z-index: 100;
}

#skill_list {
    display: inline-block;
    position: absolute;
    z-index: 100;
}

#about {
    z-index: 100;
}

#clouds  {
    display: inline-block;
    position: relative;
    z-index: 1;
    top: -20vh;
}

#balloons {
    display: inline-block;
    position: relative;
    z-index: 4;
    top: -20vh;
    animation:drift 4s infinite alternate;
}
@keyframes drift {
    to {
        transform: translateY(-15%);
    }
}

.col {
    display: block;
    float: left;
    margin: 1% 0 1% 3%;
}

.col:first-child {
    margin-left: 0;
}

/* navigation menu ----------------------------------- */
.navMenu {
    position: fixed;
    left: 0.0625em;
    top: calc(-2vw + 2.85em);
    font-size: 2em;
    cursor: pointer;
    z-index: 5000;
    
    font-weight: bold;
    text-transform: uppercase;
    transform: rotate(-90deg) translateY(-0.75em) translateX(0.5em);
    /* Safari */
    -webkit-transform: rotate(-90deg) translateY(-0.75em) translateX(0.5em);
    /* Firefox */
    -moz-transform: rotate(-90deg) translateY(-0.75em) translateX(0.5em);
    /* IE */
    -ms-transform: rotate(-90deg) translateY(-0.75em) translateX(0.5em);
    /* Opera */
    -o-transform: rotate(-90deg) translateY(-0.75em) translateX(0.5em);
}

.navMenuBackground {
    position: fixed;
    color: #ffffff;
    z-index: 5000;
    top: calc(-2vw + 2em);
    left: -1em;
    width: calc(0.5vw + 4em);
    height: calc(-1vw + 8.4em);
    border-radius: 0.5em;
    border:0px solid #000;
    text-shadow:-3px 0px 4px #000;
    box-shadow: -3px 0px 4px 4px hsla(0, 0%, 0%, 0.66);
    background-image: linear-gradient(120deg, rgb(78, 9, 9), rgb(151, 10, 10), rgb(212, 20, 20), rgb(160, 18, 18));
    background-size: 500% 500%;
    animation: menuAnimation 5s infinite;
}

@keyframes menuAnimation{
    0%{
      background-position: 0% 50%;
    }
    50%{
      background-position: 100% 50%;
    }
    100%{
      background-position: 0% 50%;
    }
}

/* Full screen menu ------------------------------------- */

.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 5000;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
    overflow-y: hidden;
    transition: 0.75s;
}

.overlay-content {
    color: #ffffff;
    font-size: 4em;
    text-decoration: none;
    display: block;
    position: relative;
    top: 10%;
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: 2em;
    transition: 0.5s;
}

.overlay-content ol:hover,
.overlay-content ol:focus {
    color: rgb(92, 92, 92);
}

.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    color: white;
    cursor: pointer;
}

@media screen and (max-height: 450px) {
    .overlay {
        overflow-y: auto;
    }

    .overlay a {
        font-size: 20px
    }

    .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 35px;
    }
}

/* Main Modal css ------------------------------------------ */
.btn_terms {
    background: transparent;
    color: #ffffff;
    display: inline-block;
    font-size: 1em;
    padding: 0.75em 1em;
    text-decoration: none;
    text-align: center;
    position: relative;
    transition: color 0.1s ease;
}

.btn_terms:hover {
    color: #357ebd;
}

.btn_terms_bottom {
    position: relative;
    background-color: #9c0000;
    color: white;
    padding: 0.75em 1.25em;
    border: none;
    border-radius: 0.5em;
    cursor: pointer;
    font-weight: 800;
    font-size: 1em;
    text-decoration: none;
    width: 3.5vw;
}

.btn_terms_bottom:hover {
    background-color: #770000;
    cursor: pointer;
    color: lightgrey;
}

.btn_terms_bottom:active {
    background-color: #a82424;
    cursor: pointer;
    color: lightgray;
}

.btn_terms-close {
    color: #aaa;
    font-size: 30px;
    text-decoration: none;
    position: absolute;
    right: 1em;
    top: 0;
}

.btn_terms-close:hover {
    color: #919191;
}

.modal:before {
    content: "";
    display: none;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.modal:target:before {
    display: block;
}

.modal:target .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    top: 10%;
}

.modal-dialog {
    background: #fefefe;
    border: #333 solid 1px;
    border-radius: 0.5em;
    position: fixed;
    left: 33vw;
    z-index: 600;
    width: 33vw;
    -webkit-transform: translate(0, -500%);
    -ms-transform: translate(0, -500%);
    transform: translate(0, -500%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
}

.modal-body {
    color: black;
    padding: 1.5em;
    width: 90%;
    height: 47vh;
    position: relative;
    overflow: auto;
}

.modal-header,
.modal-footer {
    padding: 1.25em 1em;
}

.modal-header {
    border-bottom: #eee solid 2px;
}

.modal-footer {
    padding: 1.25em;
    border-top: #eee solid 2px;
    text-align: right;
}

/* contact modal css ------------- */
.modalDialog_contact {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    opacity: 0;
    -webkit-transition: opacity 100ms ease-in;
    -moz-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
    pointer-events: none;
}

.modalDialog_contact:target {
    opacity: 1;
    pointer-events: auto;
}

.modalDialog_contact>div {
    max-width: 90%;
    width: 65%;
    position: relative;
    margin: 2% auto;
    padding: 0px;
    border-radius: 0.75em;
    background: rgb(63, 63, 63);
}

.close_contact {
    font-family: Arial, Helvetica, sans-serif;
    background: #b60015;
    color: #ffffff;
    line-height: 25px;
    position: absolute;
    right: calc(3vw + -2.5em);
    text-align: center;
    top: calc(1.125vw + -1.25em);
    width: 34px;
    height: 34px;
    text-decoration: none;
    font-weight: bold;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -moz-box-shadow: 1px 1px 3px #000;
    -webkit-box-shadow: 1px 1px 3px #000;
    box-shadow: 1px 1px 3px #000;
    padding-top: 5px;
    z-index: 101;
}

.close_contact:hover {
    background: #e93260;
}


/* main footer css ------------------------------------ */
.footer {
    z-index: 50;
    height: auto;
    width: 100%;
    position: relative;
    background-color: #000000;
}

.footer .col {
    margin-left: 4em;
    display: inline-block;
}

#personal_photo {
    right: 20em;
}

.footer .col h1 {
    font-family: inherit;
    font-size: 1em;
    line-height: 1.2em;
    color: rgba(255, 255, 255, 0.363);
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    text-align: left;
}

.footer .col ul {
    height: auto;
    list-style-type: none;
    margin-left: 0;
    padding: 0;
}

.footer .col ul li {
    color: #b6b6b6;
    font-size: 1em;
    font-family: inherit;
    font-weight: bold;
    padding: 0.25em 0 0.25em 0;
    cursor: pointer;
    transition: .2s;
    -webkit-transition: .2s;
    -moz-transition: .2s;
}

.footer .col ul li a{
    text-decoration: none;
    color: #b6b6b6;
    font-size: 1em;
    font-family: inherit;
    font-weight: bold;
    padding: 0.25em 0 0.25em 0;
    cursor: pointer;
    transition: .2s;
    -webkit-transition: .2s;
    -moz-transition: .2s;
}

.social ul li {
    top: 4em;
    display: inline-block;
    padding-right: 1em !important;
}

ul#socialbuttons li a img {
    transition: transform 0.5s ease-in-out;
}

ul#socialbuttons li:hover a img {
    transform: rotateX(180deg) rotateY(180deg) rotateZ(180deg);
}

.footer .col ul li:hover {
    color: #666666;
    transition: .1s;
    -webkit-transition: .1s;
    -moz-transition: .1s;
}

.footer .col ul li a:hover {
    color: #666666;
    transition: .1s;
    -webkit-transition: .1s;
    -moz-transition: .1s;
}

.clearfix {
    clear: both;
}

.bottom-bar {
    position: relative;
    text-align: center;
    font-size: .8em;
    text-transform: uppercase;
    background: #000;
    color: white;
}

/* monitor media styling ------------------- */
@media only screen and (min-width: 1601px) {

    .navMenu {
        position: fixed;
        top: 10vh;
    }

    .navMenuBackground {
        position: fixed;
        top: 5vh
    }

    .skills {
        clear: both;
        content: center;
        padding-left: 6em;
        height: 80vh;
        z-index: 50;
        position: relative;
    }
    .projects {
        clear: both;
        padding-left: 5em;
        padding-right: 5em;
        height: 70vh;
        z-index: 50;
        position: relative;
    }

    .parallax {
        clear: both;
        z-index: 1;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1600px) {
    .contain {
        width: 100vw;
        margin: 0 auto;
    }

    .intro {
        height: 100vh;
        width: 100vw;
        z-index: 50;
        position: relative;
    }

    .particles {
        height: 200%;
    }

    .about {
        clear: both;
        color: #ffffff;
        padding-left: 6em;
        height: 100vh;
        z-index: 500;
        position: relative;
    }

    .skills {
        clear: both;
        content: center;
        padding-left: 6em;
        height: 105vh;
        z-index: 50;
        position: relative;
    }

    .projects {
        clear: both;
        padding-left: 5em;
        padding-right: 5em;
        height: 90vh;
        z-index: 50;
        position: relative;
    }

    .parallax {
        clear: both;
        z-index: 1;
    }

    .contact {
        clear: both;
        height: 10vh;
        z-index: 500;
    }
}
/* iPad and iPad Pro Media styling --------------- */
@media only screen and (min-width: 768px) and (max-width: 1025px) {
    .footer .col {
        width: 100%;
    }

    .footer .col h1 {
        font-size: 1.5em;
    }

    .footer .col ul li {
        font-size: 1.5em;
    }

    .contain .social {
        width: 100%;
        display: block;
    }

    .social h1 {
        margin: 0px;
    }

    .intro {
        height: 100vh;
        width: 100vw;
        z-index: 50;
        position: relative;
    }

    .particles {
        height: 300%;
    }

    .about {
        clear: both;
        color: #ffffff;
        padding-left: 6em;
        height: 80vh;
        z-index: 500;
        position: relative;
    }

    .skills {
        clear: both;
        content: center;
        padding-left: 6em;
        height: 120vh;
        z-index: 50;
        position: relative;
    }

    .projects {
        clear: both;
        padding-left: 3em;
        padding-right: 3em;
        height: 0;
        z-index: 50;
        position: relative;
    }

    .parallax {
        clear: both;
        z-index: 1;
    }

    .contact {
        clear: both;
        height: 70vh;
        z-index: 500;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1025px) and (orientation: landscape) {
    .footer .col {
        width: 100%;
    }

    .footer .col h1 {
        font-size: 1.5em;
    }

    .footer .col ul li {
        font-size: 1.5em;
    }
    .intro {
        height: 100vh;
        width: 100vw;
        z-index: 50;
        position: relative;
    }

    .particles {
        height: 300%;
    }

    .about {
        clear: both;
        color: #ffffff;
        padding-left: 6em;
        height: 90vh;
        z-index: 500;
        position: relative;
    }

    .skills {
        clear: both;
        content: center;
        padding-left: 6em;
        height: 90vh;
        z-index: 50;
        position: relative;
    }

    .projects {
        clear: both;
        padding-left: 5em;
        padding-right: 5em;
        height: 90vh;
        z-index: 50;
        position: relative;
    }

    .parallax {
        clear: both;
        z-index: 1;
    }

    .contact {
        clear: both;
        height: 5vh;
        z-index: 500;
    }
    .bottom-bar {
        position: relative;
        text-align: center;
        font-size: .8em;
        text-transform: uppercase;
        background: #000;
        color: white;
    }
}
/* iphone X media styling -------------- */
@media only screen and (min-width: 375px) and (max-width: 767px) {
    .footer .col {
        width: 50%;
    }

    .footer .col h1 {
        font-size: 1em;
    }

    .footer .col ul li {
        font-size: 1em;
    }
    .intro {
        height: 100vh;
        width: 100vw;
        z-index: 50;
        position: relative;
    }

    .particles {
        height: 300%;
    }

    .about {
        clear: both;
        color: #ffffff;
        padding-left: 3.5em;
        height: 100vh;
        z-index: 500;
        position: relative;
    }

    .skills {
        clear: both;
        content: center;
        padding-left: 3.5em;
        height: 210vh;
        z-index: 50;
        position: relative;
    }

    .projects {
        clear: both;
        padding-left: 3.5em;
        padding-right: 2em;
        height: 140vh;
        z-index: 50;
        position: relative;
    }

    .parallax {
        clear: both;
        z-index: 1;
    }

    .contact {
        clear: both;
        height: 5vh;
        z-index: 500;
    }
}

/* Galaxy S5 media styling ------------- */
@media only screen and (max-width: 374px) {
    .footer .col {
        width: 75%;
        font-size: 1.5em;
        /* text-align: center; */
        float: left;
    }
    .intro {
        height: 100vh;
        width: 100vw;
        z-index: 50;
        position: relative;
    }

    .particles {
        height: 200vh;
    }

    .about {
        clear: both;
        color: #ffffff;
        padding-left: 3.5em;
        height: 160vh;
        z-index: 500;
        position: relative;
    }

    .skills {
        clear: both;
        content: left;
        padding-left: 3.5em;
        height: 260vh;
        z-index: 50;
        position: relative;
    }

    .projects {
        clear: both;
        padding-left: 3.5em;
        padding-right: 1em;
        height: 200vh;
        z-index: 50;
        position: center;
    }

    .parallax {
        clear: both;
        z-index: 1;
    }

    .contact {
        clear: both;
        height: 5vh;
        z-index: 500;
    }
}