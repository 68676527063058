.container_contactMe {
    width: calc(30vw + 16em);
    background-color: rgb(63, 63, 63);
    font-size: calc(0.6vw + 0.5em);
    color: #ffffff;
    font-weight: 700;
    border-radius: 0.75em;
    text-transform: uppercase;
    padding: 1.75vw;
    margin-top: 3vh;
    position: relative;
}

input[type=text],
input[type=email],
select,
textarea {
    width: 100%;
    padding: 1.25em;
    border: 1px solid #ccc;
    border-radius: 0.5em;
    box-sizing: border-box;
    margin-top: 1em;
    margin-bottom: 1.5em;
    resize: vertical;
}

button[type=submit] {
    background-color: #4CAF50;
    color: white;
    padding: 1em 2em;
    border: none;
    border-radius: 0.5em;
    cursor: pointer;
    margin: 1em 0;
    font-weight: 800;
    font-size: 1em;
}

button[type=submit]:hover {
    background-color: #38813c;
    color: rgb(207, 207, 207);
}

button:disabled,
button[disabled] {
    background-color: #585858;
    color: rgb(207, 207, 207);
}

button:disabled:hover,
button[disabled=hover] {
    background-color: #8d8d8d;
    color: rgb(207, 207, 207);
}

button[type=reset] {
    background-color: rgb(175, 76, 76);
    color: white;
    padding: 1em 2em;
    border: none;
    border-radius: 0.5em;
    cursor: pointer;
    margin: 1em;
    font-weight: 800;
    font-size: 1em;
}

button[type=reset]:hover {
    background-color: #813838;
    color: rgb(207, 207, 207);
}

.success {
    margin: 2em 0;
    border: 1px solid green;
    padding: 0.5em;
    font-weight: 400;
}