.skillsMain {
    width: 95%;
    max-width: 100vw;
    box-sizing: border-box;
}

.skillsMain h2 {
    padding-top: 1em;
    color: #ffffff;
    font-family: 'Montserrat',
    sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    width: 45vw;
    font-size: calc(0.5vw + 1.5em);
    line-height: calc(0.1vw + 1.2em);
    text-align: left;
    text-shadow: 2px -2px 3px #2b2b2b,
    3px -3px 3px #242424,
    4px -4px 3px #131313;
    -moz-transform-origin: bottom left;
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
    -moz-transform: skewY(1deg);
    -webkit-transform: skewY(1deg);
    -ms-transform: skewY(1deg);
    -o-transform: skewY(1deg);
    transform: skewY(1deg);
    z-index: 50;
}


.skills_item {
    float: left;
    width: 45%;
}

.skills_wrapper:after {
    content: "";
    display: table;
    clear: both;
}

@media screen and (max-width: 800px) {
    .skills_item {
        width: 90%;
    }
}

/* skill graph ----------------------------------- */

.graph_wrapper {
    padding-top: 3vh;
    position: relative;
}

.graph {
    height: 2em;
    width: calc(55vw + 2em);
    margin: 0.4em;
    line-height: 2em;
    font-size: 1em;
    color: white;
    padding: 0 0 0 1em;
    position: relative;
    z-index: 4;
    float:left;
    overflow: hidden;
}

.graph::before {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    height: 30px;
    top: 0;
    z-index: 4;
    background: transparent;
}

.graph::after {
    content: '';
    background: #2ecc71;
    height: 2em;
    transition: 0.7s;
    display: block;
    width: 100%;
    -webkit-animation: bar-before 1 1.8s;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.bar0::after {
    max-width: 56%;
}

.bar1::after{
    max-width: 67%;
}

.bar2::after {
    max-width: 58%;
}

.bar3::after {
    max-width: 62%;
}

.bar4::after {
    max-width: 55%;
}

.bar5::after {
    max-width: 40%;
}

.bar5::after {
    max-width: 45%;
}

.bar6::after {
    max-width: 43%;
}

@keyframes bar-before {
  0%{
    width: 0px;
  }
  100%{
    width: 100%;
  }
}

/* experience icons --------------------------------- */

.skill_item_icons {
    padding-top: 3vh;
}

.row::after {
    content: "";
    clear: both;
    display: table;
}

.skill_icons_main {
    float: left;
    height: auto;
    width: 100%;
    position: relative;
    background-color: transparent;
}

.skill_icons_main #skillIcons {
    display: inline-block;
}

.skill_icons_main h3 {
    padding-left: 1em;
    padding-top: 0.5em;
    font-family: inherit;
    font-size: 1em;
    line-height: 0.25em;
    color: rgba(0, 0, 0, 0.767);
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.125em;
    text-align: left;
}

.skill_icons_main #skillIcons p {
    font-family: inherit;
    font-size: 0.8em;
    line-height: 0.25em;
    color: rgba(0, 0, 0, 0.61);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-align: center;
}

.skill_icons_main ul {
    list-style-type: none;
    margin: 0;
    padding: 0.0625em;
    overflow: hidden;
}

.skill_icons_main ul li{
    float: left;
}

.skill_icons_main ul li a {
    color: rgba(0, 0, 0, 0.61);
    padding-left: 1em;
    display: block;
    text-align: center;
    text-decoration: none;
}
.skill_icons_main ul li a img {
    max-width: 100%;
    height: auto;
}

ul#skillIcons li a img{
    transition: transform 0.5s ease-in-out;
}

ul#skillIcons li:hover a img {
    transform: rotateX(180deg) rotateY(180deg) rotateZ(180deg);
}

#skillIcons {
    position: relative;
    display: block;
}


@media (min-width: 1025px) and (max-width: 1280px) {
    .skill_icons_main {
        width: 100vw;
        margin: 0 auto;
    }
}

/* ipad sizing --------------------- */
@media (min-width: 767px) and (max-width: 1024px) {
    .skill_icons_main {
        width: 90%;
        display: block;
    }

    .skill_icons_main h1 {
        margin: 0px;
    }
}

@media (min-width: 767px) and (max-width: 1024px) and (orientation: landscape) {
    .skill_icons_main #skillIcons {
        width: 50%;
    }

    .skill_icons_main h1 {
        font-size: 1em;
    }

    .skill_icons_main #skillIcons ul li {
        font-size: 1em;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .skill_icons_main #skillIcons a{
        width: 75%;
    }

    .skill_icons_main #skillIcons h1 {
        font-size: 1em;
    }

    .skill_icons_main #skillIcons ul li {
        font-size: 1em;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .skill_icons_main #skillIcons a {
        display: block;
        justify-content: left;
        width: 80%;
        font-size: 1em;
    }
}