.cloud{
  background-color: #fff;
  width: 9em;
  height: 7em;
  border-top-right-radius: 8em;
  border-top-left-radius: 12em;
  border-bottom-right-radius: 4em;
  border-bottom-left-radius: 4em;
  position: relative;
  z-index: 2;
}
  .cloud:after {
    content: '';
    z-index: -1;
    position: absolute;
    background-color:#fff;
    left: 70%;
    bottom: 0;
    width: 5em;
    height: 65%;
    border-top-right-radius: 5em;
    border-bottom-right-radius: 5em;
  }
  .cloud:before {
   content: '';
    z-index: -1;
    width: 100px;
    height: 60%;
    position: absolute;
    background-color: #fff;
    left: -25px;
    bottom: 20px;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
}

.cloud_group_1 {
  display: inline-block;
  flex-flow: wrap;
  z-index: 1;
  animation-delay: -25s;
}

.c1 {
-webkit-animation: moveclouds 35s linear infinite;
  opacity: 0.65;
  top: 3em;
  z-index: 1;
}

.c2{
  -webkit-transform: scale(0.6);
  opacity: 0.3;
  -webkit-animation: moveclouds 44s linear infinite;
  left: 20em;
  top: 0.5em;
}

.c3{
  -webkit-transform: scale(0.8);
  opacity: 0.5;
  -webkit-animation: moveclouds 32s linear infinite;
  top: 4em;
  z-index: 1;
  left: 10em;
}

.c4{
  opacity: 0.8;
  -webkit-animation: moveclouds 52s linear infinite;
  left: 30em;
  top: 6em;
  z-index: 1;
}

.c5{
  opacity: 0.3;
  -webkit-transform: scale(0.4);
  -webkit-animation: moveclouds 27s linear infinite;
  left: 3em;
  top: 9em;
  z-index: 1;
}

.cloud_group_2 {
  display: inline-block;
  flex-flow: wrap;
  z-index: 1.1;
  animation-delay: -22s;
}

.c6 {
  -webkit-animation: moveclouds 55s linear infinite;
  opacity: 0.8;
  top: 11em;
  z-index: 1;
}

.c7 {
  -webkit-transform: scale(6.5);
  opacity: 0.3;
  -webkit-animation: moveclouds 90s linear infinite;
  left: 30em;
  top: 7em;
}

.c8 {
  -webkit-transform: scale(2);
  opacity: 1;
  -webkit-animation: moveclouds 55s linear infinite;
  top: 9em;
  z-index: 1;
  left: 10em;
}

.c9 {
  opacity: 0.8;
  -webkit-animation: moveclouds 23.75s linear infinite;
  left: 30em;
  top: 28em;
  z-index: 1;
}

.c10 {
  opacity: 0.2;
  -webkit-transform: scale(0.75);
  -webkit-animation: moveclouds 32s linear infinite;
  left: 3em;
  top: 8em;
  z-index: 1;
}

.cloud_group_3 {
  display: inline-block;
  flex-flow: wrap;
  z-index: 1.2;
  animation-delay: -24s;
}

.c11 {
  opacity: 0.23;
  -webkit-transform: scale(5.75);
  -webkit-animation: moveclouds 67s linear infinite;
  left: 33em;
  top: 38.75em;
  z-index: 1;
}

.c12 {
  opacity: 0.7;
  -webkit-transform: scale(0.75);
  -webkit-animation: moveclouds 59s linear infinite;
  left: 3em;
  top: 11.5em;
  z-index: 1;
}

.c13 {
  opacity: 0.9;
  -webkit-transform: scale(3);
  -webkit-animation: moveclouds 78s linear infinite;
  left: 9em;
  top: 42.5em;
  z-index: 1;
}

.c14 {
  opacity: 0.4;
  -webkit-transform: scale(1.75);
  -webkit-animation: moveclouds 42s linear infinite;
  left: 22em;
  top: 25.5em;
  z-index: 1;
}

.c15 {
  opacity: 0.2;
  -webkit-transform: scale(7);
  -webkit-animation: moveclouds 120s linear infinite;
  left: 16em;
  top: 2.65em;
  z-index: 1;
}

.cloud_group_4 {
  display: inline-block;
  flex-flow: wrap;
  z-index: 1.3;
  animation-delay: -26s;
}

.c16 {
  opacity: 0.2;
  -webkit-transform: scale(0.75);
  -webkit-animation: moveclouds 39s linear infinite;
  left: 19em;
  top: 32em;
  z-index: 1;
}

.c17 {
  left: 200px;
  top: -100px;

	-webkit-transform: scale(0.6);
	-moz-transform: scale(0.6);
	transform: scale(0.6);
	opacity: 0.6;
	-webkit-animation: moveclouds 45s linear infinite;
	-moz-animation: moveclouds 45s linear infinite;
	-o-animation: moveclouds 45s linear infinite;
}

.c18 {
	left: -250px;
	top: -180px;

	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 0.8;
	-webkit-animation: moveclouds 55.5s linear infinite;
	-moz-animation: moveclouds 55.5s linear infinite;
	-o-animation: moveclouds 55.5s linear infinite;
}

.c19 {
	left: 470px;
	top: -150px;

	-webkit-transform: scale(0.75);
	-moz-transform: scale(0.75);
	transform: scale(0.75);
	opacity: 0.75;
	-webkit-animation: moveclouds 31s linear infinite;
	-moz-animation: moveclouds 31s linear infinite;
	-o-animation: moveclouds 31s linear infinite;
}

.c20 {
	left: -150px;
	top: -50px;

	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 0.8;
	-webkit-animation: moveclouds 60s linear infinite;
	-moz-animation: moveclouds 60s linear infinite;
	-o-animation: moveclouds 60s linear infinite;
}

@-webkit-keyframes moveclouds {
		0% {
		  background-position: 2765px 100%;
		}
	  100% {
	    background-position: 550px 100%;
	}
}
@-moz-keyframes moveclouds {
		0% {
		  background-position: 2765px 100%;
		}
		100% {
		  background-position: 550px 100%;
		}
}
@-o-keyframes moveclouds {
		0% {
		  background-position: 2765px 100%;
		}
		100% {
		  background-position: 550px 100%;
		}
}

@keyframes moveclouds {
		0% {
		  background-position: 2765px 100%;
		}
	100% {
	  background-position: 550px 100%;
	}
}