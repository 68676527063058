.welcome_container h1 {
    top: 11vh;
    color: #ffffff;
    font-family: 'Montserrat',
        sans-serif;
    font-weight: 800;
    position: absolute;
    text-transform: uppercase;
    width: 100vw;
    letter-spacing: -0.05em;
    font-size: calc(7vw + 2em);
    height: 20vh;
    line-height: 20vh;
    text-align: center;
    text-shadow: 2px -1px 0 #494949,
    2px -2px 0 #3a3a3a,
    3px -3px 0 #353535,
    4px -4px 0 #252525;
    -moz-transform-origin: bottom left;
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
    -moz-transform: skewY(1deg);
    -webkit-transform: skewY(1deg);
    -ms-transform: skewY(1deg);
    -o-transform: skewY(1deg);
    transform: skewY(1deg);
    z-index: 4;
}

.welcome_container h1:first-child {
    top: 11.5vh;
    position: absolute;
    color: rgba(0, 0, 0, 0);
    transform: rotateX(70deg);
    text-shadow: #000000 0 0 5px;
    transform-origin: bottom right;
        -moz-transform-origin: bottom right;
        -webkit-transform-origin: bottom right;
        -ms-transform-origin: bottom right;
    transform: skewX(-18deg);
        -moz-transform: skewX(-18deg);
        -webkit-transform: skewX(-18deg);
        -ms-transform: skewX(-18deg);
        -o-transform: skewX(-18deg);
    z-index: 4;

}