/* timeline box */
.timelineMaster {
    box-sizing: border-box;
    height: 85vh;
    overflow: auto;
}

.timeLine {
    position: relative;
    max-width: 600px;
    margin: 0 0 auto;
}

.timeLine::after {
    content: '';
    position: absolute;
    width: 2px;
    background: #CCD1D9;
    top: 1em;
    bottom: 0;
    left: 50%;
    margin-left: -1px;
}

.timeline_container {
  padding: 8px 40px;
  position: relative;
  background-color: inherit;
  width: 35%;
}

.timeline_container:after {
  content: '';
  position: absolute;
  width: 1.25em;
  height: 1.25em;
  right: -1.5em;
  background-color: #4FC1E9;
  border: 4px solid #F5F7FA;
  top: 1vh;
  border-radius: 50%;
  z-index: 1;
}

.timeline_right {
    left: 50%;
}

.timeline_right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid #ffffff;
    border-width: 10px 10px 10px 0;
    border-color: transparent rgba(255, 255, 255, 0.1) transparent transparent;
}

.timeline_right::after {
    left: -1em;
}

.timeline_left {
    right: 0%;
}

.timeline_left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid #ffffff;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.1);
}

.timeline_content {
  padding: 15px 15px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  border-radius: 0.25em;
}

.timeline_content h3 {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    font-size: 1.125em;
    font-weight: 500;
    color: #ffff;
}

@media screen and (max-width: 800px) {
    .timeLine::after {
        left: 2em;
    }

    .timeline_container {
        width: 65%;
        padding-left: 70px;
        padding-right: 20px;
    }

    .timeline_container::before {
        left: 60px;
        border: medium solid #ffffff;
        border-width: 10px 10px 10px 0;
        border-color: transparent rgba(255, 255, 255, 0.1) transparent transparent;
    }

    .timeline_left::after,
    .timeline_right::after {
        left: 1.1em;
    }

    .timeline_right {
        left: 0%;
    }
}