.gallery {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
    z-index: 50;
}

.galleryItem {
    width: 100%;
    display: inline-block;
    cursor: pointer;
}

.gallery_header h2 {
    color: #ffffff;
    font-family: 'Montserrat',
    sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    width: 45vw;
    font-size: calc(0.6vw + 1.5em);
    line-height: calc(0.5vw + 1.4em);
    text-shadow: 2px -2px 3px #2b2b2b,
    3px -3px 3px #242424,
    4px -4px 3px #131313;
    -moz-transform-origin: bottom left;
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
    -moz-transform: skewY(1deg);
    -webkit-transform: skewY(1deg);
    -ms-transform: skewY(1deg);
    -o-transform: skewY(1deg);
    transform: skewY(1deg);
    z-index: 50;
}

.galleryItem .galleryTitle h3 {
    color: #ffffff;
    font-family: 'Montserrat',
    sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    width: 30vw;
    font-size: calc(0.5vw + 1em);
    line-height: calc(0.5vw + 1.1em);
    text-shadow: 2px -2px 3px #2b2b2b,
    3px -3px 3px #242424,
    4px -4px 3px #131313;
    -moz-transform-origin: bottom left;
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
    -moz-transform: skewY(1deg);
    -webkit-transform: skewY(1deg);
    -ms-transform: skewY(1deg);
    -o-transform: skewY(1deg);
    transform: skewY(1deg);
    z-index: 50;
}

.galleryItem p {
    color: #ffffff;
    text-shadow: 2px -2px 3px #2b2b2b;
}

.galleryItem img {
    display: block;
    margin: 0 auto;
    width: 90%;
    height: auto;
    border-radius: .5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .75);
    z-index: 50;
}

.galleryItem>img:hover {
    cursor: pointer;
    transform: scale(2);
}

@media only screen and (min-width: 500px) {
    .gallery {
        max-width: 719px;
    }

    .galleryItem {
        width: calc((100% / 2) - 10px);
    }

    .galleryItem:nth-child(even) {
        margin-left: 20px;
    }
}

@media only screen and (min-width: 720px) {
    .gallery {
        max-width: 100%;
        padding: 20px 20px 0 0;
    }

    .galleryItem {
        width: calc((100% / 3) - 20px);
    }

    .galleryItem:nth-child(odd) {
        margin-left: 20px;
    }
}

/* project card styling content --------------- */
.gallery_container {
  position: relative;
  width: auto;
  height: auto;
}

.galleryOverlay {
    position: absolute;
    top: -1vh;
    right: 1.5vh;
    width: 95%;
    height: 95%;
    border-radius: 0.5em;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
}

.gallery_container:hover .galleryOverlay {
    display: block;
    background: rgba(0, 0, 0, .95);
}

.galleryButtonGroup {
    cursor: pointer;
    float: center;
    margin: 1vw;
}

.galleryButtonGroup:after {
    content: "";
    clear: both;
    display: table;
}

.galleryButtonSource {
    background-color: rgb(63, 63, 63);
    font-size: calc(0.6vw + 0.25em);
    color: #ffffff;
    font-weight: 200;
    border-radius: 0.75em;
    text-transform: uppercase;
    padding: 0.8vw;
    position: absolute;
    width: 10em;
    left: 11%;
    top: 60%;
    text-align: center;
    opacity: 0;
    transition: opacity .35s ease;
    z-index: 1;
}

.galleryButtonSource a {
    text-decoration: none;
    color: #ffffff;
}

.galleryButtonSource:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: rgb(0, 110, 9);
}

.galleryButtonLaunched {
    background-color: rgb(63, 63, 63);
    font-size: calc(0.6vw + 0.25em);
    color: #ffffff;
    font-weight: 200;
    border-radius: 0.75em;
    text-transform: uppercase;
    padding: 0.8vw;
    position: absolute;
    width: 10em;
    right: 11%;
    top: 60%;
    text-align: center;
    opacity: 0;
    transition: opacity .35s ease;
    z-index: 1;
}

.galleryButtonLaunched a {
    text-decoration: none;
    color: #ffffff;
}

.galleryButtonLaunched:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: rgb(165, 0, 0);
}

.galleryDescription {
    color: #ffffff;
    position: absolute;
    width: 80%;
    right: 3vw;
    top: 5%;
    text-align: center;
    font-size: calc(1.25vh + 0.25em);
    opacity: 0;
    transition: opacity .35s ease;
}

.gallery_container:hover .galleryButtonSource {
    opacity: 1;
}

.gallery_container:hover .galleryButtonLaunched {
    opacity: 1;
}

.gallery_container:hover .galleryDescription {
    opacity: 1;
}