/* about main wrapper --------------------------------- */
.about_main {
    box-sizing: border-box;
}

.about_item {
    float: left;
    width: 45%;
    padding-top: 2em;
}

.about_wrapper:after {
    content: "";
    display: table;
    clear: both;
}

@media screen and (max-width: 800px) {
    .about_item {
        width: 85%;
    }
}

/* about me section ------------------------------ */

.about_me h4 {
    color: #ffffff;
    font-family: 'Permanent Marker',
        cursive;
    position: relative;
    width: 45vw;
    letter-spacing: 0.1em;
    font-size: calc(0.125vw + 1.3em);
    line-height: calc(0.125vw + 1.3em);
    text-shadow: 3px -3px 3px #242424,
        4px -4px 3px #131313;
    -moz-transform-origin: bottom left;
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
    -moz-transform: skewY(0.15);
    -webkit-transform: skewY(0.15);
    -ms-transform: skewY(0.15);
    -o-transform: skewY(0.15);
    transform: skewY(0.15);
    z-index: 50;
}

.about_me h3 {
    color: #ffffff;
    font-family: 'Montserrat',
        sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    width: 45vw;
    font-size: calc(0.5vw + 1.5em);
    line-height: calc(0.2vw + 1.4em);
    text-align: left;
    text-shadow: 2px -2px 3px #2b2b2b,
        3px -3px 3px #242424,
        4px -4px 3px #131313;
    -moz-transform-origin: bottom left;
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
    -moz-transform: skewY(1deg);
    -webkit-transform: skewY(1deg);
    -ms-transform: skewY(1deg);
    -o-transform: skewY(1deg);
    transform: skewY(1deg);
    z-index: 50;
}

.about_me {
    color: #ffffff;
    text-shadow: 1px -1px 1px #242424;
    font-size: calc(0.00125vw + 0.9em);
    z-index: 50;
    width: 90%;
}
