.flip-card {
    background-color: transparent;
    width: 200px;
    height: 200px;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;

    backface-visibility: hidden;
}

.flip-card-front img {
    border-radius: 50%;
    height: 20vh;
    width: auto;
    border-color: #585858;
    border-block-width: 2px;
    border-style: solid;
}

.flip-card-back img {
    height: 20vh;
    width: auto;
}

.flip-card-back {
    border-radius: 50%;
    transform: rotateY(180deg);
}