.parallax {
    z-index: -10;
    bottom: 0;
    left: 0;
    right: 0;
    position: relative;
    width: 100vw;
    overflow: hidden;
    height: 100vh;
}

.parallaxLayer {
    width: 200vw;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}

.parallax__cover {
    background: linear-gradient(to bottom, rgb(8,8,8), #000000);
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: calc(0.25vw + 0.75em);
    z-index: 200;
}


.parallaxLayer#p1 {
    top: -8vh !important;
    object-fit: cover;
    position: absolute !important;
    background-image:url("https://github.com/DanBurbach/PersonalPortfolio_2.0/blob/master/src/assets/parallax_images/layer_1.png?raw=true");
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.60;
    margin-left: -42vw;
    margin-right: -40vw;
}

.parallaxLayer#p2 {
    width: 140vw;
    object-fit: cover;
    top: 42vh !important;
    position: center !important;
    object-position: 50% 50% !important;
}

.parallaxLayer#p3 {
    width: 140vw;
    object-fit: cover;
    top: 13vh !important;
    position: relative !important;
    object-position: 50% 50% !important;
}

.parallaxLayer#p4 {
    width: 140vw;
    object-fit: cover;
    top: 34vh !important;
    position: absolute !important;
    object-position: 50% 50% !important;
}

.parallaxLayer#p5 {
    width: 140vw;
    object-fit: cover;
    top: 34vh !important;
    position: absolute !important;
    object-position: 50% 50% !important;
    margin-left: -10vw;
}

.parallaxLayer#p6 {
    object-fit: cover;
    top: 30vh !important;
    position: absolute !important;
    object-position: 50% 50% !important;
    opacity: 1;
}


/* media query sizing for parallax based on screen image */

@media only screen and (min-width: 1601px) {
    .parallaxLayer#p1 {
        top: -5vh !important;
        position: center !important;
        background-image: url("https://github.com/DanBurbach/PersonalPortfolio_2.0/blob/master/src/assets/parallax_images/layer_1.png?raw=true");
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        object-fit: cover;
        object-position: 50% 50% !important;
    }

    .parallaxLayer#p2 {
        width: 140vw;
        object-fit: cover;
        top: 55vh !important;
        position: center !important;
        object-position: 50% 50% !important;
    }

    .parallaxLayer#p3 {
        width: 140vw;
        object-fit: cover;
        top: 25vh !important;
        position: absolute !important;
        object-position: 50% 50% !important;
    }

    .parallaxLayer#p4 {
        width: 300vw;
        object-fit: cover;
        top: 40vh !important;
        position: absolute !important;
        object-position: 50% 50% !important;
    }

    .parallaxLayer#p5 {
        width: 140vw;
        object-fit: cover;
        top: 45vh !important;
        position: absolute !important;
        object-position: 50% 50% !important;
    }
    .parallaxLayer#p6 {
        opacity: 1;
        object-fit: cover;
        top: calc(5vw + 29em) !important;
        position: absolute !important;
        object-position: 50% 50% !important;
    }
}

@media only screen and (min-width: 1025px) and (max-width:1600) {
.parallaxLayer#p6 {
    opacity: 1;
    object-fit: cover;
    top: calc(2vw + 12em) !important;
    position: absolute !important;
    object-position: 50% 50% !important;
}
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {

.parallaxLayer#p1 {
    top: -8vh !important;
    position: absolute !important;
    background-image: url("https://github.com/DanBurbach/PersonalPortfolio_2.0/blob/master/src/assets/parallax_images/layer_1.png?raw=true");
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.60;
    margin-left: -42vw;
    margin-right: -40vw;
}
    
.parallaxLayer#p2 {
    width: 140vw;
    object-fit: cover;
    top: 59vh !important;
    position: center !important;
    object-position: 50% 50% !important;
}
.parallaxLayer#p3 {
    width: 140vw;
    object-fit: cover;
    top: 38vh !important;
    position: absolute !important;
    object-position: 50% 50% !important;
}
.parallaxLayer#p4 {
    width: 140vw;
    object-fit: cover;
    top: 52vh !important;
    position: absolute !important;
    object-position: 50% 50% !important;
}
.parallaxLayer#p5 {
    width: 140vw;
    object-fit: cover;
    top: 52vh !important;
    position: absolute !important;
    object-position: 50% 50% !important;
}
.parallaxLayer#p6 {
    opacity: 1;
    object-fit: cover;
    top: calc(35vw + 28em) !important;
    position: absolute !important;
    object-position: 50% 50% !important;
}
}

@media only screen and (min-width: 767px) and (max-width: 1024px) and (orientation: landscape) {
.parallaxLayer#p1 {
    top: -8vh !important;
    position: absolute !important;
    background-image: url("https://github.com/DanBurbach/PersonalPortfolio_2.0/blob/master/src/assets/parallax_images/layer_1.png?raw=true");
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.60;
    margin-left: -42vw;
    margin-right: -40vw;
}
.parallaxLayer#p2 {
    width: 140vw;
    object-fit: cover;
    top: 60vh !important;
    position: center !important;
    object-position: 50% 50% !important;
}
.parallaxLayer#p6 {
    opacity: 1;
    object-fit: cover;
    top: calc(35vw + 28em) !important;
    position: absolute !important;
    object-position: 50% 50% !important;
}
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
.parallaxLayer#p6 {
    opacity: 1;
    object-fit: cover;
    top: calc(30vw + 14em) !important;
    position: absolute !important;
    object-position: 50% 50% !important;
}
}

@media only screen and (min-width: 361px) and (max-width: 480px) {
.parallaxLayer#p6 {
    opacity: 1;
    object-fit: cover;
    top: calc(2vw + 20em) !important;
    position: absolute !important;
    object-position: 50% 50% !important;
}
}
@media only screen and (max-width: 360px) {
    .parallaxLayer#p6 {
        opacity: 1;
        object-fit: cover;
        top: calc(3vw + 10em) !important;
        position: absolute !important;
        object-position: 50% 50% !important;
    }
}